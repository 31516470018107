@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: 'Poppins', -apple-system, BlinkMacSystemFont, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: linear-gradient(135deg, #ff758c 0%, #ff7eb3 100%);
}

/* Custom scrollbar */
::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 0.1);
}

::-webkit-scrollbar-thumb {
  background: rgba(255, 20, 147, 0.5);
  border-radius: 4px;
}

/* Glass effect */
.glass {
  backdrop-filter: blur(8px);
  background: rgba(255, 255, 255, 0.1);
}

/* Update cursor styles */
* {
  cursor: none !important;
}

button, 
a, 
[role="button"],
[type="button"] {
  cursor: none !important;
}

.custom-cursor {
  position: fixed;
  top: 0;
  left: 0;
  pointer-events: none;
  z-index: 10000;
  will-change: transform;
}

.cursor-heart {
  color: #ff1493;
  filter: drop-shadow(0 0 4px rgba(255, 20, 147, 0.7));
  width: 24px;
  height: 24px;
  transform-origin: center;
}

.cursor-heart-trail {
  filter: drop-shadow(0 0 2px rgba(255, 20, 147, 0.4));
  transform-origin: center;
  pointer-events: none;
  will-change: transform, opacity;
  position: absolute;
  top: 0;
  left: 0;
}

/* Add hover effect for interactive elements */
button:hover .cursor-heart,
a:hover .cursor-heart,
[role="button"]:hover .cursor-heart {
  transform: scale(1.2);
  filter: drop-shadow(0 0 8px rgba(255, 20, 147, 0.9));
} 